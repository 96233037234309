import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {Facebook} from "react-bootstrap-icons";
import {BsFlower3} from "react-icons/all";
import Image from "react-bootstrap/Image";

class Contact extends Component {
    render() {
        return (
            <div>
                <Container fluid style={{marginTop: "100px", background: "#fff"}} >
                    <Row>

                        <Container style={{maxWidth:"1200px"}} className={"pbl"}>
                            <Row>
                                <Col>
                                    <h2 id="kontaktoss" className={"mtxl"}>Kontakt oss</h2>
                                    <div className={"divider-custom divider-light"}>
                                        <div className={"divider-custom-line"}></div>
                                        <BsFlower3 size={40}></BsFlower3>
                                        <div className={"divider-custom-line"}></div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className={"mtm mbm"}>
                                <Col>
                                    <Image fluid roundedCircle src="images/elin.jpg"/>
                                    <h6 className={"mtm"}>Elin Bromstad</h6>
                                    <div className={"mts"}>Innehaver</div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} md={12} lg={4} className={"mtl"}>
                                    <h3>Telefon</h3>
                                    <div><a href={"tel:91724626"}>91724626</a></div>
                                </Col>
                                <Col xs={12} md={12} lg={4} className={"mtl"}>
                                    <h3>Facebook</h3>
                                    <a href={"https://www.facebook.com/steinkjergravstell"} target={"_blank"}><Facebook size={40}></Facebook></a>
                                </Col>
                                <Col xs={12} md={12} lg={4} className={"mtl"}>
                                    <h3>Epost</h3>
                                    <a href={"mailto:steinkjergravstell@gmail.com"}>steinkjergravstell@gmail.com</a>
                                </Col>
                            </Row>

                        </Container>
                    </Row>
                </Container>
                <Container fluid style={{paddingTop: "50px", background: "#000"}}>
                    <Row><Col style={{color:"#FFF"}}></Col></Row>
                </Container>
            </div>
        );
    }
}

export default Contact;
