import React, {Component} from 'react';
import {BsFlower3} from "react-icons/all";

class Divider extends Component {
    render() {
        return (
            <div className={"divider-custom divider-light"}>
                <div className={"divider-custom-line"}></div>
                <BsFlower3 size={40}></BsFlower3>
                <div className={"divider-custom-line"}></div>
            </div>
        );
    }
}

export default Divider;
