import React, {Component} from 'react';
import Image from "react-bootstrap/Image";
import Carousel from "react-bootstrap/Carousel";

class CarouselImages extends Component {
    render() {
        return (
            <Carousel variant="dark" indicators={false} interval={null}>
                <Carousel.Item>
                    <Image fluid width={"80%"} height={"30%"}
                           src="images/carousel/storstemor.jpg"/>
                </Carousel.Item>

                <Carousel.Item>
                    <Image fluid width={"80%"} height={"60%"}
                           src="images/carousel/georginer.jpg"/>
                </Carousel.Item>
                <Carousel.Item>
                    <Image fluid width={"80%"} height={"60%"}
                           src="images/carousel/gulknapp.jpg"/>
                </Carousel.Item>
                <Carousel.Item>
                    <Image fluid width={"80%"} height={"30%"}
                           src="images/carousel/mange_hostlyng.jpg"/>
                </Carousel.Item>

                <Carousel.Item>
                    <Image fluid width={"60%"} height={"30%"}
                           src="images/carousel/rose_i_host.jpg"/>
                </Carousel.Item>

                <Carousel.Item>
                    <Image fluid width={"80%"} height={"30%"} src="images/carousel/sommer.jpg"/>
                </Carousel.Item>
                <Carousel.Item>
                    <Image fluid width={"80%"} height={"30%"}
                           src="images/carousel/sommer_2.jpg"/>
                </Carousel.Item>
                <Carousel.Item>
                    <Image fluid width={"80%"} height={"30%"}
                           src="images/carousel/sommerblomster.jpg"/>
                </Carousel.Item>

                <Carousel.Item>
                    <Image fluid width={"80%"} height={"30%"}
                           src="images/carousel/tagetes.jpg"/>
                </Carousel.Item>
                <Carousel.Item>
                    <Image fluid width={"80%"} height={"30%"}
                           src="images/carousel/urne_beplanting.jpg"/>
                </Carousel.Item>
                <Carousel.Item>
                    <Image fluid width={"80%"} height={"30%"}
                           src="images/carousel/mosekrans.jpg"/>
                </Carousel.Item>

                <Carousel.Item>
                    <Image fluid width={"80%"} height={"50%"}
                           src="images/carousel/einer_jul.jpg"/>
                </Carousel.Item>

            </Carousel>
        );
    }
}

export default CarouselImages;
