import React, {Component} from 'react';
import {Form} from "react-bootstrap";

class FormError extends Component {
    render() {
        return (
        <div>
            {!this.props.isError && this.props.showErrorMessages ?
                <Form.Text className={"validationerror"}>
                    {this.props.errorMessage}
                </Form.Text>
               :<div/>
            }
        </div>
        );
    }
}
export default FormError;
