import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import {HashRouter, Route, Routes} from "react-router-dom";
import Bestilling from "./Bestilling";
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
        <HashRouter>
            <Routes>
            <Route path="/" element={<App/>}/>
            <Route path="/bestilling" element={<Bestilling/>}/>
            </Routes>
        </HashRouter>
    ,

    document.getElementById('root')
);
