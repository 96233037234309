import React, {Component} from 'react';
import './App.css';
import {Button, Col, Container, Row} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Image from 'react-bootstrap/Image'
import {LinkContainer} from 'react-router-bootstrap'
import Contact from "./nav/contact";
import Footer from "./nav/footer";
import About from "./nav/about";
import Header from "./nav/header";
import CarouselImages from "./nav/carousel";
import Divider from "./nav/divider";

class App extends Component {
    render() {
        return (
            <div className="App">
                <Header/>


                <Container fluid style={{background: "#BDCFB6"}} className={"pbxl"}>
                    <Row>

                        <Container style={{maxWidth: "1200px"}}>
                            <Row>
                                <Col>
                                    <div className={"mtm"} style={{fontSize: "140%"}}>
                                        Vi sørger for et fint og velstelt gravsted.
                                    </div>
                                    <h2 id="produkter" className={"mtxxl"}>Produkter</h2>
                                        <Divider/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={4} className={"mbxl"}>
                                    <h4 className={"mtl"}>Vår / Sommer</h4>
                                    <Image fluid roundedCircle width={"50%"} height={"50%"} src="images/stemor.jpg"/>
                                    <div className={"mtl text-left"}>Gravstellsavtale med utplanting av vårblomster i
                                        mai og sommerblomster i juni.
                                    </div>
                                    <div className={"mtl"}>Pris pr. år: 2.000,-
                                    </div>
                                    <div className={"mtl"}>
                                        <LinkContainer to="/bestilling">
                                            <Button variant="secondary" size="lg">Bestill</Button>
                                        </LinkContainer>
                                    </div>


                                </Col>
                                <Col xs={12} md={12} lg={4} className={"mbxl"}>
                                    <h4 className={"mtl"}>Vår / sommer / høst</h4>
                                    <Image fluid roundedCircle width={"50%"} height={"50%"} src="images/sommer.jpg"/>
                                    <div className={"mtl"}>Gravstellsavtale med utplanting av vårblomster i mai,
                                        sommerbeplantning i juni og høstlyng i september.
                                    </div>
                                    <div className={"mtl"}>Vi ordner med gravlys til allehelgens.</div>
                                    <div className={"mtl"}>Pris pr. år: 2.700,-
                                    </div>
                                    <div className={"mtl"}>
                                        <LinkContainer to="/bestilling">
                                            <Button variant="secondary" size="lg">Bestill</Button>
                                        </LinkContainer>
                                    </div>

                                </Col>
                                <Col xs={12} md={12} lg={4} className={"mbxl"}>

                                    <h4 className={"mtl"}>Vår / sommer / høst / vinter </h4>
                                    <Image className={"mtl"} fluid roundedCircle width={"50%"} height={"50%"} src="images/lyng.jpg"/>
                                    <div className={"mtl"}>Gravstellsavtale med utplanting av vårblomster i mai,
                                        sommerbeplantning i juni, beplantning av høstlyng i
                                        september med granbar og krans til jul.
                                    </div>
                                    <div className={"mtl"}>Vi ordner med gravlys til allehelgens og jul.</div>
                                    <div className={"mtl"}>Pris pr. år: 3.100,-
                                    </div>
                                    <div className={"mtl"}>
                                        <LinkContainer to="/bestilling">
                                            <Button variant="secondary" size="lg">Bestill</Button>
                                        </LinkContainer>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col >
                                    <h4 className={"mtxl"}>Utvidet stelleavtale</h4>
                                    <div className={"mtl mbl"}>
                                        For familiegravsteder eller gravsteder som har ekstra store bed, kan vi tilby en utvidet stelleavtale.
                                        Vi hjelper deg gjerne med å holde familiegravstedet fint og velstelt. Pris avtales etter befaring.
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h4 className={"mtxl"}>Selvvanningskasse </h4>
                                    <Image fluid width={"50%"} height={"50%"} src="images/selvvanningskasse.jpg"/>
                                    <div className={"mtl mbl"}>Vi tilbyr nedgraving av selvvanningskasser som består av
                                        to deler. Den ene delen blir gravd ned og fylt med vann, den andre delen plantes
                                        det i.
                                        Kassene er vedlikeholdsfrie, frostsikre, er laget av resirkulert plast og har
                                        lang holdbarhet.
                                        Størrelsen på kassen tilpasses gravstedet, vi har halvmånekasser for mindre
                                        gravsteder. Etterfylling av vann er inkludert i prisen.

                                    </div>
                                    <div className={"mtl"}>Pris for etablering av selvanningskasse er 2.000,-</div>

                                    <div className={"mtl mbl"}>Bestilling av selvvanningskasse gjøres sammen med
                                        bestilling av gravstellsavtale.
                                    </div>
                                    <div className={"mtl"}>
                                        <LinkContainer to="/bestilling">
                                            <Button variant="secondary" size="lg">Bestill</Button>
                                        </LinkContainer>

                                    </div>


                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h4 className={"mtxl"}>Om avtalen med oss</h4>
                                    <p className={"mtl mbl"}>En gravstellavtale gjelder for 1 år og vil automatisk bli
                                        fornyet ved årsskifte om den ikke blir sagt opp. Avtaler kan bestilles året
                                        rundt, uavhengig av sesong. Vi avtaler pris før fakturering om bestillingen blir
                                        gjort seinere på året.
                                        Vi hjelper deg også om du har spesielle ønsker for gravstedet, for eksempel
                                        dekorstein, avskårne blomster eller en gravlykt.
                                    </p>
                                </Col>
                            </Row>
                            <Row className={"mtl"}>
                                <Col>
                                    <h2 id="produkter" className={"mtxl"}>Bilder fra forrige sesong</h2>
                                    <Divider/>

                                    <CarouselImages/>
                                </Col>
                            </Row>

                        </Container>
                    </Row>
                </Container>


                <About/>
                <Contact/>
                <Footer/>
            </div>
        );
    }
}

export default App;
