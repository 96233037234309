import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import {Oval} from 'react-loader-spinner'

class Loading extends Component {
    render() {
        return (
            <Row className={"mal"}>
                <Col md={{ offset: 5 }} xs={{ offset: 4 }} >
                    <Oval
                        heigth="150"
                        width="150"
                        color='#BDCFB6'
                        ariaLabel='loading'
                    />
                </Col>
            </Row>



        );
    }
}

export default Loading;
