import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {BsFlower3} from "react-icons/all";
import Image from "react-bootstrap/Image";

class About extends Component {
    render() {
        return (
            <Container fluid style={{background: "#BDCFB6"}}>
                <Row>

                    <Container style={{maxWidth:"1200px"}}>
                        <Row>
                            <Col>
                                <h2 id="omoss" className={"mtxl"}>Om oss</h2>
                                <div className={"divider-custom divider-light"}>
                                    <div className={"divider-custom-line"}></div>
                                    <BsFlower3 size={40}></BsFlower3>
                                    <div className={"divider-custom-line"}></div>
                                </div>
                            </Col>
                        </Row>
                            <Row>
                                <Col>
                                    <p>Steinkjer Gravstell er en lokalt forankret bedrift som tilbyr gravstell på Steinkjer, Egge og Beitstad gravlund i Steinkjer kommune. Vi utfører beplantning og stell, og tilpasser planter som passer for de ulike sesongene.
                                        Vi innehar tillatelse fra Steinkjer kirkelige fellesråd om stell av gravsteder i Steinkjer kommune. </p>
                                </Col>
                            </Row>
                        <Row>
                            <Col>
                                <Image fluid className={"mbm mtl"}  src="images/elin_stell.jpg" title={"Foto: Trine Binde Bratberg"}/>
                                <div className={"mbxl"}>Foto: Trine Binde Bratberg</div>
                            </Col>
                        </Row>

                    </Container>
                </Row>
            </Container>
        );
    }
}

export default About;
