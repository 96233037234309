import React, {Component} from 'react';
import './App.css';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import Contact from "./nav/contact";
import Footer from "./nav/footer";
import About from "./nav/about";
import Header from "./nav/header";
import FormError from "./form/FormError";
import Loading from "./nav/loading";

class Bestilling extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navnGravsted: '',
            kirkegard: '',
            plassering: '',
            avtale: 'var_sommer_host',
            selvvanning: false,
            onsker: '',
            kontaktnavn: '',
            kontaktmobil: '',
            kontaktepost: '',
            fakturanavn: '',
            fakturaadresse: '',
            fakturapostnummer: '',
            fakturapoststed: '',
            fakturaepost: false,
            navnGravstedValid: false,
            kirkegardValid: false,
            kontaktnavnValid: false,
            kontaktmobilValid: false,
            kontaktepostValid: false,
            fakturanavnValid: false,
            fakturaadresseValid: false,
            fakturapostnummerValid: false,
            fakturapoststedValid: false,
            formValid: false,
            generatedKey: '',
            activesubmit: false,
            submitsuccess: false,
            submiterror: false,
            showerrormessages: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.activateValidation = this.activateValidation.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.getGeneratedKey = this.getGeneratedKey.bind(this);
    }


    componentDidMount() {
        window.scrollTo(0, 0)
    }
    handleChange(e) {

        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
        this.validateField(name, value);
        if(this.state.generatedKey === ''){
            this.getGeneratedKey();
        }
    }

    activateValidation(e) {
        var hasStarted = this.state.navnGravstedValid
            || this.state.kirkegardValid
            || this.state.kontaktnavnValid
            || this.state.kontaktmobilValid
            || this.state.kontaktepostValid
            || this.state.fakturanavnValid
            || this.state.fakturaadresseValid
            || this.state.fakturapostnummerValid
            || this.state.fakturapoststedValid
        if (hasStarted && !this.state.showerrormessages) {
            this.setState({showerrormessages: true});
        }

    }

    async getGeneratedKey() {
        const response = await fetch("./api/v1/key", {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
        const json = await  response.json();
        this.setState({
            generatedKey: json.generatedKey
        });


    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({activesubmit: true, submiterror: false})

        fetch("./api/v1/bestilling", {
            method: 'POST',
            body: JSON.stringify(this.state),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {

            if (response.status === 202) {
                this.setState({
                    activesubmit: false,
                    submitsuccess: true,
                    submiterror: false
                });
            } else {
                this.setState({
                    activesubmit: false,
                    submitsuccess: false,
                    submiterror: true
                });
            }
        });

    }

    validateField(fieldName, value) {
        switch (fieldName) {

            case 'navnGravsted':
                this.setState({navnGravstedValid: value.length >= 2}, () => {
                    this.validateForm();
                });
                break;
            case 'kirkegard':
                this.setState({kirkegardValid: value.length >= 2}, () => {
                    this.validateForm();
                })
                break;
            case 'kontaktepost':
                let validEmail = this.validateEmail(value) != null;
                this.setState({kontaktepostValid: validEmail === true}, () => {
                    this.validateForm();
                });
                break;
            case 'kontaktnavn':
                this.setState({kontaktnavnValid: value.length >= 3}, () => {
                    this.validateForm();
                })
                break;
            case 'kontaktmobil':
                this.setState({kontaktmobilValid: value.length >= 8}, () => {
                    this.validateForm();
                })
                break;
            case 'fakturanavn':
                this.setState({fakturanavnValid: value.length >= 4}, () => {
                    this.validateForm();
                })
                break;
            case 'fakturaadresse':
                this.setState({fakturaadresseValid: value.length >= 1}, () => {
                    this.validateForm();
                })
                break;
            case 'fakturapostnummer':
                this.setState({fakturapostnummerValid: value.length >= 4}, () => {
                    this.validateForm();
                })
                break;
            case 'fakturapoststed':
                this.setState({fakturapoststedValid: value.length >= 2}, () => {
                    this.validateForm();
                })
                break;
            default:
                break;
        }
    }

    validateEmail(email) {
        return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }

    validateForm() {
        let isValidForm = this.state.navnGravstedValid
            && this.state.kirkegardValid
            && this.state.kontaktnavnValid
            && this.state.kontaktmobilValid
            && this.state.kontaktepostValid
            && this.state.fakturanavnValid
            && this.state.fakturaadresseValid
            && this.state.fakturapostnummerValid
            && this.state.fakturapoststedValid;
        this.setState({formValid: isValidForm});

    }

    render() {
        return (
            <div className="App">
                <Header/>

                <Container fluid style={{background: "#BDCFB6"}} className={"pbxl"}>
                    <Row>

                        <Container style={{maxWidth: "1200px"}}>

                            <h2 className={"mtxl"}>Bestilling av gravstell</h2>
                            {this.state.submiterror ?
                                <Row className={"mbxl"}>
                                    <Col><h5>Det oppsto en feil ved innsending av bestillingen. Prøv igjen om litt eller
                                        ta kontakt med oss. </h5></Col>
                                </Row> :
                                <div/>
                            }
                            {this.state.activesubmit ?
                                <Loading/> :
                                <div/>
                            }
                            {!this.state.activesubmit && !this.state.submitsuccess ?
                                <Row className={"mbxl"}>
                                    <div className={"panel panel-default"}>
                                    </div>
                                    <Form onSubmit={this.handleSubmit}>
                                        <h5 className={"textleft"}>Om gravstedet</h5>
                                        <Row className={"mal textleft"}>

                                            <Col xs={"12"} lg={3}>
                                                <Form.Label htmlFor={"navnGravsted"} className={"mts"}>Navn på
                                                    gravsted:</Form.Label>
                                                <span className={"redstar plm"}>*</span>
                                                <FormError isError={this.state.navnGravstedValid}
                                                           errorMessage={"Navn på gravsted må være fylt ut. "}
                                                           showErrorMessages={this.state.showerrormessages}/>
                                            </Col>
                                            <Col xs={"12"} lg={9}>
                                                <Form.Control id={"navnGravsted"} name={"navnGravsted"} type="text"
                                                              value={this.state.navnGravsted}
                                                              placeholder="Navn på gravsted"
                                                              onChange={this.handleChange}/>
                                                <Form.Text className="text-muted">
                                                    Hvilket navn står det på gravsteinen, hvis det står flere så er det
                                                    nokmed ett.
                                                </Form.Text>
                                            </Col>
                                        </Row>

                                        <Row className={"mal textleft"}>
                                            <Col xs={"12"} lg={3}>
                                                <Form.Label htmlFor={"kirkegard"}
                                                            className={"mts"}>Kirkegård:</Form.Label>
                                                <span className={"redstar plm"}>*</span>
                                                <FormError isError={this.state.kirkegardValid}
                                                           errorMessage={"Kirkegård må være fylt ut. "}
                                                           showErrorMessages={this.state.showerrormessages}/>
                                            </Col>
                                            <Col xs={"12"} lg={9}>
                                                <Form.Control id={"kirkegard"} name={"kirkegard"} type="text"
                                                              value={this.state.kirkegard}
                                                              placeholder="På hvilken kirkegård er graven"
                                                              onChange={this.handleChange}/>
                                            </Col>
                                        </Row>

                                        <Row className={"mal textleft"}>
                                            <Col xs={"12"} lg={3}>
                                                <Form.Label htmlFor={"plassering"}
                                                            className={"mts"}>Plassering:</Form.Label>
                                            </Col>
                                            <Col xs={"12"} lg={9}>
                                                <Form.Control id={"plassering"} name={"plassering"} type="text"
                                                              value={this.state.plassering}
                                                              placeholder="Hvor på kirkegården ligger graven"
                                                              onChange={this.handleChange}/>
                                                <Form.Text className="text-muted">
                                                    Hvis du har feltnavn er det til stor hjelp, men også omtrenglig
                                                    plassering hjelper oss å finne graven.
                                                </Form.Text>
                                            </Col>
                                        </Row>
                                        <h5 className={"textleft"}>Gravstell</h5>

                                        <Row className={"mal textleft"}>
                                            <Col xs={"12"} lg={3}>
                                                <Form.Label htmlFor={"avtale"} className={"mts"}>Avtale:</Form.Label>
                                                <span className={"redstar plm"}>*</span>
                                            </Col>
                                            <Col xs={"12"} lg={9}>
                                                <Form.Select aria-label="Valg av gravstellsavtale" id={"avtale"}
                                                             name={"avtale"} value={this.state.avtale}
                                                             onChange={this.handleChange}>
                                                    <option value="var_sommer">Vår / sommer</option>
                                                    <option value="var_sommer_host">Vår / sommer / høst</option>
                                                    <option value="var_sommer_host_vinter">Vår / sommer / høst /
                                                        vinter
                                                    </option>
                                                </Form.Select>
                                                <Form.Text className="text-muted">
                                                    Velg hvilken gravstellsavtale dere ønsker
                                                </Form.Text>

                                            </Col>

                                        </Row>

                                        <Row className={"mal textleft"}>
                                            <Col xs={"12"} lg={3}>
                                                <Form.Label htmlFor={"selvvanning"}
                                                            className={"mts"}>Selvvanningskasse:</Form.Label>
                                            </Col>
                                            <Col xs={"12"} lg={9}>
                                                <Form.Check type="switch" id="selvvanning" name="selvvanning"
                                                            checked={this.state.selvvanning}
                                                            label="Vi ønsker selvvanningskasse"
                                                            onChange={this.handleChange}/>
                                                <Form.Text className="text-muted">
                                                    Huk av for denne hvis dere ønsker selvvanningskasse.
                                                </Form.Text>
                                            </Col>

                                            <Col xs={"12"} lg={3} ></Col>
                                            <Col xs={"12"} lg={9} className={"mtl"}>Alle gravstellsavtaler inkluderer jordforbedring, gjødsling, stell og tilsyn med gravstedet. </Col>

                                        </Row>
                                        <Row className={"mal textleft"}>
                                            <Col xs={"12"} lg={3}>
                                                <Form.Label htmlFor={"onsker"}
                                                            className={"mts"}>Spesielle ønsker:</Form.Label>
                                            </Col>
                                            <Col xs={"12"} lg={9}>
                                                <Form.Control id={"onsker"} name={"onsker"} type="text"
                                                              value={this.state.onsker}
                                                              placeholder="Spesielle ønsker"
                                                              onChange={this.handleChange}/>
                                                <Form.Text className="text-muted">
                                                    Dette kan f.eks være om dere vil ha gravlykt, annen pynt eller har spesielle merkedager som dere ønsker å markere.
                                                </Form.Text>
                                            </Col>
                                        </Row>

                                        <h5 className={"textleft"}>Kontaktperson</h5>
                                        <Row className={"mal textleft"}>
                                            <Col xs={"12"} lg={3}>
                                                <Form.Label htmlFor={"kontaktnavn"} className={"mts"}>Navn på
                                                    kontaktperson:</Form.Label>
                                                <span className={"redstar plm"}>*</span>
                                                <FormError isError={this.state.kontaktnavnValid}
                                                           errorMessage={"Navn på kontaktperson må være fylt ut. "}
                                                           showErrorMessages={this.state.showerrormessages}/>
                                            </Col>
                                            <Col xs={"12"} lg={9}>
                                                <Form.Control id={"kontaktnavn"} name={"kontaktnavn"} type="text"
                                                              value={this.state.kontaktnavn} placeholder="Kontaktperson"
                                                              onChange={this.handleChange}/>
                                                <Form.Text className="text-muted">
                                                    Hvem kan vi kontakte og sende bilde til om det er ønskelig.
                                                </Form.Text>
                                            </Col>
                                        </Row>
                                        <Row className={"mal textleft"}>
                                            <Col xs={"12"} lg={3}>
                                                <Form.Label htmlFor={"kontaktmobil"}
                                                            className={"mts"}>Mobilnummer:</Form.Label>
                                                <span className={"redstar plm"}>*</span>
                                                <FormError isError={this.state.kontaktmobilValid}
                                                           errorMessage={"Mobilnummer til kontaktperson må være fylt ut. "}
                                                           showErrorMessages={this.state.showerrormessages}/>
                                            </Col>
                                            <Col xs={"12"} lg={9}>
                                                <Form.Control id={"kontaktmobil"} name={"kontaktmobil"} type="text"
                                                              value={this.state.kontaktmobil} placeholder="Mobilnummer"
                                                              onChange={this.handleChange}/>

                                            </Col>
                                        </Row>
                                        <Row className={"mal textleft"}>
                                            <Col xs={"12"} lg={3}>
                                                <Form.Label htmlFor={"kontaktepost"}
                                                            className={"mts"}>Epost:</Form.Label>
                                                <span className={"redstar plm"}>*</span>
                                                <FormError isError={this.state.kontaktepostValid}
                                                           errorMessage={"Epost til kontaktperson må være fylt ut og være på riktig format. "}
                                                           showErrorMessages={this.state.showerrormessages}/>
                                            </Col>
                                            <Col xs={"12"} lg={9}>
                                                <Form.Control id={"kontaktepost"} name={"kontaktepost"} type="text"
                                                              value={this.state.kontaktepost} placeholder="Epost"
                                                              onChange={this.handleChange}/>
                                            </Col>
                                        </Row>
                                        <h5 className={"textleft"}>Faktura</h5>
                                        <Row className={"mal textleft"}>
                                            <Col xs={"12"} lg={3}>
                                                <Form.Label htmlFor={"fakturanavn"} className={"mts"}>Navn:</Form.Label>
                                                <span className={"redstar plm"}>*</span>
                                                <FormError isError={this.state.fakturanavnValid}
                                                           errorMessage={"Navn til fakturamottaker må være fylt ut. "}
                                                           showErrorMessages={this.state.showerrormessages}/>
                                            </Col>
                                            <Col xs={"12"} lg={9}>
                                                <Form.Control id={"fakturanavn"} name={"fakturanavn"} type="text"
                                                              value={this.state.fakturanavn} placeholder="Navn"
                                                              onChange={this.handleChange}/>
                                            </Col>
                                        </Row>
                                        <Row className={"mal textleft"}>
                                            <Col xs={"12"} lg={3}>
                                                <Form.Label htmlFor={"fakturaadresse"}
                                                            className={"mts"}>Adresse:</Form.Label>
                                                <span className={"redstar plm"}>*</span>
                                                <FormError isError={this.state.fakturaadresseValid}
                                                           errorMessage={"Fakturaadresse må være fylt ut. "}
                                                           showErrorMessages={this.state.showerrormessages}/>
                                            </Col>
                                            <Col xs={"12"} lg={9}>
                                                <Form.Control id={"fakturaadresse"} name={"fakturaadresse"} type="text"
                                                              value={this.state.fakturaadresse} placeholder="Adresse"
                                                              onChange={this.handleChange}/>

                                            </Col>
                                        </Row>
                                        <Row className={"mal textleft"}>
                                            <Col xs={"12"} lg={3}>
                                                <Form.Label htmlFor={"fakturapostnummer"}
                                                            className={"mts"}>Postnummer:</Form.Label>
                                                <span className={"redstar plm"}>*</span>
                                                <FormError isError={this.state.fakturapostnummerValid}
                                                           errorMessage={"Postnummer må være fylt ut. "}
                                                           showErrorMessages={this.state.showerrormessages}/>
                                            </Col>
                                            <Col xs={"12"} lg={9}>
                                                <Form.Control id={"fakturapostnummer"} name={"fakturapostnummer"}
                                                              type="text" value={this.state.fakturapostnummer}
                                                              placeholder="Postnummer" onChange={this.handleChange}/>
                                            </Col>
                                        </Row>
                                        <Row className={"mal textleft"}>
                                            <Col xs={"12"} lg={3}>
                                                <Form.Label htmlFor={"fakturapoststed"}
                                                            className={"mts"}>Poststed:</Form.Label>
                                                <span className={"redstar plm"}>*</span>
                                                <FormError isError={this.state.fakturapoststedValid}
                                                           errorMessage={"Poststed må være fylt ut. "}
                                                           showErrorMessages={this.state.showerrormessages}/>
                                            </Col>
                                            <Col xs={"12"} lg={9}>
                                                <Form.Control id={"fakturapoststed"} name={"fakturapoststed"}
                                                              type="text" value={this.state.fakturapoststed}
                                                              placeholder="Poststed" onChange={this.handleChange}/>
                                            </Col>
                                        </Row>
                                        <Row className={"mal textleft"}>
                                            <Col xs={"12"} lg={3}>
                                                <Form.Label htmlFor={"fakturaepost"} className={"mts"}>Sende faktura på
                                                    epost:</Form.Label>
                                            </Col>
                                            <Col xs={"12"} lg={9}>
                                                <Form.Check type="switch" id="fakturaepost" name="fakturaepost"
                                                            label="Vi ønsker faktura på epost"
                                                            checked={this.state.fakturaepost}
                                                            onChange={this.handleChange}/>
                                                <Form.Text className="text-muted">Huk av for denne hvis dere ønsker
                                                    faktura på epost. Papirfaktura koster kr. 35,-</Form.Text>
                                            </Col>
                                            <Col xs={"12"} lg={3} ></Col>
                                            <Col xs={"12"} lg={9} className={"mtl"}>Avtalen gjelder for et år av gangen og blir automatisk fornyet dersom den ikke blir sagt opp.
                                                Gravstellsavtalen vil bli fakturert på starten av året. Vi gir beskjed hvis vi endrer prisene.</Col>

                                        </Row>
                                        <Row onMouseEnter={this.activateValidation}>
                                            <Col>
                                                {!this.state.formValid && this.state.showerrormessages ?
                                                    <Form.Text className={"validationerror mbl"}>
                                                        <div>Alle påkrevde felter må være fylt ut før du kan sende inn
                                                            bestillingen.
                                                        </div>
                                                    </Form.Text>
                                                    : <div/>
                                                }

                                                <Button variant="primary" type="submit" disabled={!this.state.formValid}
                                                        className={"mtl"}>
                                                    Send bestilling
                                                </Button>
                                            </Col>
                                        </Row>

                                    </Form>


                                </Row> :
                                <div/>
                            }
                            {this.state.submitsuccess ?
                                <Row className={"mbxl"}>
                                    <Col>
                                        <h5>Takk for bestillingen.</h5>
                                        <div>
                                            Vi tar kontakt hvis det er noe vi lurer på. Ta gjerne kontakt med oss hvis
                                            du lurer på noe.
                                        </div>
                                        <div>
                                            Vi har sendt en bekreftelse til deg på epost.
                                        </div>


                                    </Col>
                                </Row> :
                                <div/>
                            }
                        </Container>
                    </Row>
                </Container>
                <About/>
                <Contact/>
                <Footer/>

            </div>
        );
    }
}

export default Bestilling;
