import React, {Component} from 'react';
import {Container, Nav, Navbar, NavLink, Row} from "react-bootstrap";

class Header extends Component {
    render() {
        return (
            <div>
                <Navbar id={"mainNav"} fixed={"top"} bg={"light"} expand={"lg"} sticky={"top"}>
                    <Container>
                        <img height="80px" width="80px" src="images/flower.png" alt="icon" className={"mrl"}/>
                        <Navbar.Brand href="./#/" style={{fontSize: "1.6em"}}>Steinkjer Gravstell</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="{me-auto}" style={{fontSize: "90%"}}>
                                <NavLink href="./#/bestilling" className={"menuItem"}>Bestilling</NavLink>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Container fluid style={{padding: "0"}}>
                    <Row>
                        <img width={"100%"} height={"100%"} style={{minHeight: "100px", maxHeight: "30%"}}
                             src="images/background_margaritter.png" alt="background"/>
                    </Row>
                </Container>

            </div>
        );
    }
}

export default Header;
