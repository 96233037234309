import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";

class Footer extends Component {
    render() {
        return (
            <Container fluid style={{paddingTop: "50px", background: "#000"}}>
                <Row><Col style={{color:"#FFF"}}></Col></Row>
            </Container>
        );
    }
}

export default Footer;
